import React from "react";
import Layout from "../components/layout";
import "../styles/pages/poll.scss";

export default function Poll() {
  return (
    <Layout title="Your Opinion">
      <div className="poll">
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSdUol45XbQN9Qsb4TcCF3KVj4GKIrjngcV-Z1NJ2xyrmL7EWQ/viewform?embedded=true"
          width="100%"
          height="530"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        >
          Loading…
        </iframe>
      </div>
    </Layout>
  );
}
